@import "sass-lib";

@media (min-width: 0px) and (max-width: 1680px) {
	.servicelist{
		.ls-service{
			.desc{padding-bottom: 35px;}
		}
	}
	.sliderewards{
		.list{margin: 200px 0;}
	}
}
@media (min-width: 0px) and (max-width: 1480px) {
	header{
		.logo{
			padding: 15px 0 12px;
			img{width: 70px;}
		}
		.navigation{
			> li{
				> a{padding-bottom: 42px;}
			}
		}
		.head-right{padding-top: 30px;}
	}
	.content{padding-top: 75px;}
	.headpadd{margin-top: 100px;}
	.servicelist{
		.ls-service{
			h5{font-size: 20px; margin-bottom: 10px;}
			p{font-size: 14px; height: 46px; overflow: hidden; margin-bottom: 0;}
			.icoservice{width: 85px; height: 95px;}
			.desc{padding: 0 40px 35px;}
		}
	}
	.sliderewards{
		.list{margin: 200px 0;}
		&.minfour{
			.list{
				margin-top: 100px; margin-bottom: 100px;
			}
		}
		&.minthree{
			.list{
				margin-top: 80px; margin-bottom: 80px;
			}
		}
	}
	.boxsliderewards{margin-top: -50px;}

	.box-slide-detail{
		.leftcapsbeauty{width: 34%;}
		.slideimgdetail{width: 60%;}
	}
}

@media (min-width: 0px) and (max-width: 1400px) {
	h1{
		font-size: 40px;
		&.h1s2{font-size: 80px;}
	}
	h2{font-size: 40px;}

	.wrapper{
		width: 1170px;
		.wrap-md{max-width: 100%;}
	}

	header{
		.navigation{
			> li{
				> a{font-size: 13px; padding-bottom: 45px;}
			}
		}
		.nav-lang{
			margin-right: 20px;
			.btn-lang{padding: 12px 15px; font-size: 12px;}
			.drop-lang{
				li{font-size: 11px;}
			}
		}
		.btn-rounded{font-size: 14px; height: 40px; line-height: 34px; margin-top: -2px;}
	}

	.slidehome{
		.list{
			.desc{
				width: 1170px; margin-top: -20px;
				h5, h1, div{max-width: 470px;}
				h1{font-size: 55px; line-height: 64px; margin-bottom: 20px;}
				h5{font-size: 22px;margin-bottom: 10px;}
				.lineh{margin-bottom: 15px;}
			}
		}
	}

	.box-slidehome{
		.box-filter{width: 1170px;}
	}
	.box-filter{
		padding: 30px 50px;
		input[type=text],
	    input[type=password],
	    input[type=email],
	    input[type=number],
	    textarea{min-width: 0; width: 100%;}
		.left{width: 120px;}
		.center{width: 800px; margin-top: 13px;}
		.right{
			input[type=submit]{width: 123px;}
		}

		.row{
			width: 24%;
		}
		.selectcustom{
			.btnselect{min-width: 0; width: 100%;}
		}
	}
	.sec-service{
		.top-service{padding-top: 140px; padding-bottom: 70px;}
		.in-service{
			h2{margin-bottom: 15px;}
			h5{margin-bottom: 15px;}
		}
	}
	.sec-fasility{
		padding-top: 70px;
		.ttl-fasility{
			h5{margin-bottom: 10px;}
		}
	}
	.sec-about{
		/*> figure{max-width: 70%;}*/
	}
	.detaildokter{
		h3{font-size: 30px; margin: 20px 0 25px;}
	}
	.box-roundedgrey{
		margin-bottom: 30px;
		.ttlroundedgrey{font-size: 20px;}
	}

	footer{
		.foot-bottom{
			ul{
				padding-top: 20px;
				li{margin-bottom: 15px;}
			}
			.centerfoot{width: 670px;}
			.box-inquiry{text-align: right; float: none; margin-right: 0; padding-top: 0;}
			.box-sosmed{display: block; float: none; clear: both;}
		}
	}
}

@media (min-width: 0px) and (max-width: 1280px) {
	.wrap-md{width: 1170px;}
	.sidefasility{
		.left{width: 55%;}
		.right{width: 40%;}
	}
	.sec-news{
		.lg-news{width: 45%;}
		.sm-news{width: 52%;}
	}
	.promolg{
		.left{width: 46%;}
		.right{width: 50%;}
	}
}

@media (min-width: 0px) and (max-width: 1200px) {
	.wrapper, .wrap-md, .wrap-sm, .wrap-md2{
		width: 905px;
	}
	.wrapper{
		.wrap-sm{width: 100%;}
	}

	header{
		.navigation{
			padding-right: 10px;
			> li{
				> a{padding-left: 5px; padding-right: 5px;}
			}
		}
		.nav-lang{
			margin-right: 5px;
			.btn-lang{padding-left: 10px; padding-right: 10px;}
		}
	}

	.slidehome{
		.list{
			.desc{width: 905px;}
		}
		.slick-dots{
			li{
				button{width: 12px; height: 12px;}
			}
		}
	}
	.box-slidehome{
		.box-filter{width: 905px;}
	}
	.box-filter{
		padding-bottom: 30px;
		.left{
			h3{max-height: 160px;}
		}
		.center{width: 545px;}
		.row{
			width: 49%; margin-bottom: 20px;
			&:nth-child(even){margin-right: 0;}
		}
	}
	.sec-service{
		.top-service{padding-top: 220px;}
	}
	.sidefasility{
		.right{
			padding-top: 30px;
			.slidecapsfasility{
				.list{min-height: 0;}
			}
		}
	}
	.sec-about{
		padding-bottom: 100px; min-height: 630px;
		/*> figure{max-width: 68%;}*/
		.desc-abouthome{
			width: 50%;
			h2{margin-bottom: 20px;}
			h5{margin-bottom: 10px;}
			p{margin-bottom: 35px;}
		}
	}
	.sec-news{
		.sm-news{
			.ls-news{
				width: 50%; margin-right: 0; padding: 0 10px; @include boxSizing(border-box); background: transparent; position: relative; @include boxShadow(none);
				&:before{content: ""; position: absolute; top: 0; left: 10px; right: 10px; bottom: 0; @include borderRadius(10px); background: $white; z-index: -1;@include boxShadow(0 10px 5px 0 rgba(0,0,0,0.05));}
				.desc{
					padding-left: 15px; padding-right: 15px;
					h6{font-size: 16px; line-height: 24px; height: 72px; overflow: hidden;}
				}
			}
		}
	}
	.box-acc{
		.ls-acc{
			margin-left: 30px; margin-right: 30px;
			&.active{margin-left: 0; margin-right: 0;}
			.btn-acc{font-size: 21px; padding: 20px 45px;}
		}
	}
	.boxsliderewards{margin-top: -30px;}
	.sliderewards{
		.list{
			margin: 150px 0;
			&.next1{@include transform(scale(2));}
			&.next2{@include transform(scale(1.5));}
			&.prev1{@include transform(scale(2));}
			&.prev2{@include transform(scale(1.5));}
			&.slick-current{@include transform(scale(2.5));}
		}

	    &.minseven{
	        .list{
	        	margin: 100px 0;
	            &.slick-current{@include transform(scale(2));z-index: 2;}
	            &.prev1{@include transform(scale(1.8));z-index: -1;}
	            &.prev2{@include transform(scale(1.4));z-index: -2;}
	            &.prev3{@include transform(scale(1));z-index: -3;}
	            &.next1{@include transform(scale(1.8));z-index: -1;}
	            &.next2{@include transform(scale(1.4));z-index: -2;}
	            &.next3{@include transform(scale(1));z-index: -3;}
	        }
	    }
	    &.minfive{
	        .list{
	        	margin: 80px 0;
	            &.slick-current{@include transform(scale(1.6));z-index: 2;}
	            &.prev1{@include transform(scale(1.4));z-index: -1;}
	            &.prev2{@include transform(scale(1.2));z-index: -2;}
	            &.prev3{@include transform(scale(1));z-index: -3;}
	            &.next1{@include transform(scale(1.4));z-index: -1;}
	            &.next2{@include transform(scale(1.2));z-index: -2;}
	            &.next3{@include transform(scale(1));z-index: -3;}
	        }
	    }
	    &.minfour{
	        .list{
	        	margin: 50px 0;
	            &.slick-current{@include transform(scale(1.2));z-index: 2;}
	            &.prev1{@include transform(scale(1.1));z-index: -1;}
	            &.prev2{@include transform(scale(1));z-index: -2;}
	            &.prev3{@include transform(scale(1));z-index: -3;}
	            &.next1{@include transform(scale(1.1));z-index: -1;}
	            &.next2{@include transform(scale(1));z-index: -2;}
	            &.next3{@include transform(scale(1));z-index: -3;}
	        }
	    }
	    &.minthree{
	        .list{
	        	margin: 50px 0;
	            &.slick-current{@include transform(scale(1.05));z-index: 2;}
	            &.prev1{@include transform(scale(1));z-index: -1;}
	            &.prev2{@include transform(scale(1));z-index: -2;}
	            &.prev3{@include transform(scale(1));z-index: -3;}
	            &.next1{@include transform(scale(1));z-index: -1;}
	            &.next2{@include transform(scale(1));z-index: -2;}
	            &.next3{@include transform(scale(1));z-index: -3;}
	        }
	    }
	    &.minduo{
	        .list{
	        	margin: 30px 0;
	            &.slick-current{@include transform(scale(1));z-index: 2;}
	            &.prev1{@include transform(scale(1));z-index: -1;}
	            &.prev2{@include transform(scale(1));z-index: -2;}
	            &.prev3{@include transform(scale(1));z-index: -3;}
	            &.next1{@include transform(scale(1));z-index: -1;}
	            &.next2{@include transform(scale(1));z-index: -2;}
	            &.next3{@include transform(scale(1));z-index: -3;}
	        }
	    }
	}
	.slidecapsrewards{
		.list{
			h4{font-size: 24px;}
		}
	}
	.slideimgdetail{
		&:before{right: auto; left: 57px; width: 2px; height: 66px; bottom: -62px;}
		.desc{
			padding-right: 0;
		}
		.slick-prev, .slick-next{
			right: auto; @include transform(rotate(0deg));
		}
		.slick-prev{
			left: 0; bottom: -50px;
			&:hover{bottom: -50px; left: -5px;}
		}
		.slick-next{
			left: 75px; bottom: -50px;
			&:hover{bottom: -50px; left: 80px}
		}
	}
	.box-spesialis{
		.left{width: 50%;}
		.right{width: 45%; padding-top: 30px;}
	}
	.slidecapsspesialis{
		&:before{bottom: -44px;}
		.slick-prev{bottom: -30px;}
		.slick-next{bottom: -30px;}
	}
	.slidecontent{
		.slick-dots{
			li{
				button{width: 12px; height: 12px;}
			}
		}
	}
	.grid-accordion{
		h4{font-size: 24px;}
		.ttl-named{font-size: 21px; line-height: 28px;}
		.ga-layer{
			&.ga-padding{padding-left: 30px; padding-right: 30px;}
		}
		.ga-pagination-button{width: 15px; height: 15px;}
	}
	.classlist{
		.ls-classlist{
			width: 33.333333333333333%;
			.ttlclass, .descclass{padding-left: 60px; padding-right: 60px;}
			.desc{width: 300%;}
			/*&:nth-child(n+4){display: none;}*/
			&:nth-child(4n+1){clear: inherit;}
			&:nth-child(3n+1){
				clear: both;
				.desc{left: 0%;}
			}
	        &:nth-child(3n+2){
	            .desc{left: -100%;}
	        }
	        &:nth-child(3n){
	            .desc{left: -200%;}
	        }
		}
	}
	.tanyadokter{
		padding-bottom: 250px;
		.fbtanyadokter{padding-top: 130px;}
	}
	.box-lsdokter{
		.lsdokter{
			h3{font-size: 34px; margin-bottom: 20px;}
			.desc{
				.tbdiv{margin-bottom: 30px;}
			}
		}
	}
	.promolg{
		h3{overflow: hidden; height: 75px;}
		p{overflow: hidden; height: 56px;}
	}
	.promolist{
		.ls-promolist{
			width: 33.3333333333333333333%;
			&:nth-child(4n+1){clear: inherit;}
			&:nth-child(3n+1){clear: both;}
			&:nth-child(even){
				padding-top: 0;
	            &:before{top: 0;}
	            figure{
	                order: 1;
	                &:before{bottom: -16px; left: 0; right: auto; top: auto;}
	                img{@include borderRadius(10px 10px 0 0);}
	            }
	            .desc{order: 2;@include borderRadius(0 0 10px 10px); padding-bottom: 30px; padding-top: 42px;}
			}
	        &:nth-child(3n+2){
	            padding-top: 45px;
	            &:before{top: 45px;}
	            figure{
	                order: 2;
	                &:before{bottom: auto; left: auto; right: 0; top: -16px;}
	                img{@include borderRadius(0 0 10px 10px);}
	            }
	            .desc{order: 1;@include borderRadius(10px 10px 0 0); padding-top: 30px; padding-bottom: 42px;}
	        }
		}
	}
	.box-bysidecontent{
		h2{font-size: 30px; height: 72px; overflow: hidden;}
		p{overflow: hidden; height: 80px;}
		.left{
			width: 40%;
			img{width: 100%;}
		}
		.right{width: 55%;}
	}
	.frm-side{
		min-height: 350px;
		h2{font-size: 32px; height: 78px;}
		.left{padding-top: 20px;}
	}

	.btn-tab{
		.lsbtn-tab{
			&:hover{
	            &:before{width: 0px;}
	            .icwp{background-position: top center;}
	        }
	        &.active{
				&:hover{
		            &:before{width: 100%;}
		            .icwp{background-position: bottom center;}
		        }
	        }
		}
	}


	footer{
		.foot-top{
			.left, .right{width: 50%; @include boxSizing(border-box);}
			.left{padding-left: 0; padding-right: 15px;}
			.right{padding-right: 0; padding-left: 15px;}
		}
		.foot-bottom{
			.logo-foot{margin-right: 40px;}
			.centerfoot{
				width: 40%;
				ul{
					li{width: 50%; margin-right: 0; @include boxSizing(border-box);}
				}
			}
		}
	}
}